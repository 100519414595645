import "./App.css";
import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProtectedRoute from "./protectedRoute";
import { useAppStore } from "./appStore";
import { Alert, Snackbar } from "@mui/material";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useInactivityLogout from "./utils/browserLogoutHandle";
import Loader from "./utils/loader";
import Accounts from "./pages/Accounts/Accounts";
import RPNumbers from "./pages/Menu/menus/RP";
import ZeroPanaNumbers from "./pages/Menu/menus/ZP";
import FamilyPanaNumbers from "./pages/Menu/menus/FamilyPana";
import HierarchyAccounts from "./pages/HierarcyAccounts/HierarchyAccounts";
import LowerGameDetails from "./pages/Transactions/LowerGameDetails";
import WalletStatementPage from "./pages/Transactions/WalletStatements";
import GameWalletStatementsPage from "./pages/Transactions/GameWalletStatements";
import CPNumbers from "./pages/Menu/menus/CP";
import SendPhoto from "./pages/SendPhoto/SendPhoto";

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Login = React.lazy(() => import("./pages/Login/login"));
const Roles = React.lazy(() => import("./pages/Roles/roles"));
const PageNotFound = React.lazy(() =>
  import("./components/404page/pageNotFound")
);
const Users = React.lazy(() => import("./pages/Users/users"));
const Register = React.lazy(() => import("./components/register/register"));
const MyProfile = React.lazy(() => import("./pages/Profile/myProfile"));
const Wallet = React.lazy(() => import("./pages/Wallet/wallet"));
const WithdrawRequest = React.lazy(() =>
  import("./pages/Withdrawrequest.js/withdrawRequest")
);
const DepositRequest = React.lazy(() =>
  import("./pages/Depositrequest/depositRequest")
);
const UserDetails = React.lazy(() => import("./pages/Users/userDetails"));
const WalletSettings = React.lazy(() =>
  import("./pages/Wallet/walletSettings")
);
const CommissionSettings = React.lazy(() =>
  import("./pages/Settings/commissionSettings")
);
const AddGame = React.lazy(() => import("./pages/Game/addGame"));
const GameSelection = React.lazy(() => import("./pages/Game/gameSelection"));
const PlayGame = React.lazy(() => import("./pages/Game/playGame"));
const UpdateGame = React.lazy(() => import("./components/Game/UpdateGame"));
const CreateMenu = React.lazy(() => import("./pages/Menu/createMenu"));
const Game9Numbers = React.lazy(() => import("./pages/Menu/menus/game9"));
const MenuFormation = React.lazy(() => import("./pages/Menu/menuFormation"));
const Bulk90 = React.lazy(() => import("./pages/Menu/menus/bulk90"));
const Game90 = React.lazy(() => import("./pages/Menu/menus/game90"));
const Menu = React.lazy(() => import("./pages/Game/menus"));
const WinningRatioSettings = React.lazy(() =>
  import("./pages/Settings/winningRatioSettings")
);
const MenuSelection = React.lazy(() => import("./pages/Menu/menuSelection"));
const ShareSettings = React.lazy(() =>
  import("./pages/Settings/shareSettings")
);
const ShareList = React.lazy(() =>
  import("./components/settings/share/shareList")
);
const UpdateShare = React.lazy(() =>
  import("./components/settings/share/updateShare")
);
const SinglePanaNumbers = React.lazy(() =>
  import("./pages/Menu/menus/singlePana")
);
const DoublePana = React.lazy(() => import("./pages/Menu/menus/doublePana"));
const TriplePana = React.lazy(() => import("./pages/Menu/menus/triplePana"));
const AllInOneGames = React.lazy(() => import("./pages/Menu/menus/allInOne"));
const JodiFamily = React.lazy(() => import("./pages/Menu/menus/jodiFamily"));
const Results = React.lazy(() => import("./pages/Result/result"));
const Holiday = React.lazy(() => import("./pages/Holiday/holiday"));
const PreClosingTimeList = React.lazy(() =>
  import("./pages/Game/preClosingTimeList")
);
const EditUserDetails = React.lazy(() =>
  import("./pages/Users/editUserDetails")
);
const Report = React.lazy(() => import("./pages/Reports/report"));
const PermissionsPage = React.lazy(() =>
  import("./pages/Permissions/Permissions")
);
const LoginManagement = React.lazy(() =>
  import("./pages/LoginManagement/LoginManagement")
);
const PlaceBets = React.lazy(() => import("./pages/PlaceBets/PlaceBets"));
const Messages = React.lazy(() => import("./pages/Message/Message"));
const Subscription = React.lazy(() =>
  import("./components/subscription/Subscription")
);
const Welcome = React.lazy(() => import("./pages/welcome/Welcome"));
const Unauthorized = React.lazy(() =>
  import("./components/404page/Unauthorized")
);
const AdhikNumbers = React.lazy(() => import("./pages/Menu/menus/Adhik"));
const TotalMenuNumbers = React.lazy(() => import("./pages/Menu/menus/total"));
const CutAkdaNumbers = React.lazy(() => import("./pages/Menu/menus/cutAkda"));
const Game1000Numbers = React.lazy(() => import("./pages/Menu/menus/Game1000"));
const Game10000Numbers = React.lazy(() =>
  import("./pages/Menu/menus/Game10000")
);
const MPNumbers = React.lazy(() => import("./pages/Menu/menus/MP"));
const Announcement = React.lazy(() =>
  import("./pages/Annoucements/announcements")
);

function App() {
  // const navigate = useNavigate();
  const snackbarMessage = useAppStore((state) => state.snackbarMessage);
  const openSnackbar = useAppStore((state) => state.openSnackbar);
  const openSnackbarError = useAppStore((state) => state.openSnackbarError);
  const setopenSnackbarError = useAppStore(
    (state) => state.setOpenSnackbarError
  );
  const setopenSnackbar = useAppStore((state) => state.setOpenSnackbar);

  const closeSnackbar = () => {
    setopenSnackbar(false);
  };
  const closeSnackbarError = () => {
    setopenSnackbarError(false);
  };

  useInactivityLogout();

  return (
    <>
      <BrowserRouter>
        <Helmet defaultTitle="Satta-Makta ( M-India )">
          {/* <link rel="icon" href="/logo-vector.png" /> */}
        </Helmet>
        <ToastContainer
          position={"top-right"}
          theme="dark"
          hideProgressBar={true}
          autoClose={2000}
          transition={Slide}
        />

        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute requiredPermissions={["/dashboard"]}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute requiredPermissions={["/users"]}>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roles"
              element={
                <ProtectedRoute>
                  <Roles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/register"
              element={
                <ProtectedRoute requiredPermissions={["/users/register"]}>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myprofile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/edit-user-details"
              element={
                <ProtectedRoute>
                  <EditUserDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <ProtectedRoute requiredPermissions={["/wallet"]}>
                  <Wallet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/walletsettings"
              element={
                <ProtectedRoute>
                  <WalletSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/commissionsettings"
              element={
                <ProtectedRoute>
                  <CommissionSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/share-settings"
              element={
                <ProtectedRoute>
                  <ShareSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/share-list"
              element={
                <ProtectedRoute>
                  <ShareList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/update-share"
              element={
                <ProtectedRoute>
                  <UpdateShare />
                </ProtectedRoute>
              }
            />
            <Route
              path="/winning-ratio-settings"
              element={
                <ProtectedRoute>
                  <WinningRatioSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/withdrawrequest"
              element={
                <ProtectedRoute requiredPermissions={["/withdrawrequest"]}>
                  <WithdrawRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/depositrequest"
              element={
                <ProtectedRoute requiredPermissions={["/depositrequest"]}>
                  <DepositRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/userdetails"
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path="/reports"
            element={
              <ProtectedRoute requiredPermissions={[
                
                  "/users",
                  "/selectMenu",
                  "/playGame",
                  "/wallet",
                  "/depositrequest",
                  "/withdrawrequest",
                  "/reports",
                  "/place-bets",
                ]}>
                <UserReport />
              </ProtectedRoute>
            }
          /> */}
            <Route
              path="/reports"
              element={
                <ProtectedRoute requiredPermissions={["/reports"]}>
                  <Report />
                </ProtectedRoute>
              }
            />

            <Route
              path="/addGame"
              element={
                <ProtectedRoute>
                  <AddGame />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-games"
              element={
                <ProtectedRoute>
                  <GameSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/playGame"
              element={
                <ProtectedRoute requiredPermissions={["/playGame"]}>
                  <PlayGame />
                </ProtectedRoute>
              }
            />
            <Route
              path="/addMenu"
              element={
                <ProtectedRoute requiredPermissions={["/addMenu"]}>
                  <CreateMenu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/selectMenu"
              element={
                <ProtectedRoute requiredPermissions={["/selectMenu"]}>
                  <MenuSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/menu-formation"
              element={
                <ProtectedRoute requiredPermissions={["/menu-formation"]}>
                  <MenuFormation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Game 9"
              element={
                <ProtectedRoute>
                  <Game9Numbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Jodi Family"
              element={
                <ProtectedRoute>
                  <JodiFamily />
                </ProtectedRoute>
              }
            />
            <Route
              path="/All in one"
              element={
                <ProtectedRoute>
                  <AllInOneGames />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Bulk 90"
              element={
                <ProtectedRoute>
                  <Bulk90 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SP"
              element={
                <ProtectedRoute>
                  <SinglePanaNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/DP"
              element={
                <ProtectedRoute>
                  <DoublePana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Game 90"
              element={
                <ProtectedRoute>
                  <Game90 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/TP"
              element={
                <ProtectedRoute>
                  <TriplePana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Adhik"
              element={
                <ProtectedRoute>
                  <AdhikNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Total"
              element={
                <ProtectedRoute>
                  <TotalMenuNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Cut Akda"
              element={
                <ProtectedRoute>
                  <CutAkdaNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Game 1000"
              element={
                <ProtectedRoute>
                  <Game1000Numbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Game 10000"
              element={
                <ProtectedRoute>
                  <Game10000Numbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/MP"
              element={
                <ProtectedRoute>
                  <MPNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/RP"
              element={
                <ProtectedRoute>
                  <RPNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ZP"
              element={
                <ProtectedRoute>
                  <ZeroPanaNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Pana Family"
              element={
                <ProtectedRoute>
                  <FamilyPanaNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CP"
              element={
                <ProtectedRoute>
                  <CPNumbers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/annoucements"
              element={
                <ProtectedRoute requiredPermissions={["/annoucements"]}>
                  <Announcement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-game-details/:id"
              element={
                <ProtectedRoute>
                  <UpdateGame />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/:name"
              element={
                <ProtectedRoute>
                  <Menu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/result-declaration"
              element={
                <ProtectedRoute requiredPermissions={["/result-declaration"]}>
                  <Results />
                </ProtectedRoute>
              }
            />
            <Route
              path="/declare-holiday"
              element={
                <ProtectedRoute requiredPermissions={["/declare-holiday"]}>
                  <Holiday />
                </ProtectedRoute>
              }
            />
            <Route
              path="/preclosing-time-list"
              element={
                <ProtectedRoute requiredPermissions={["/preclosing-time-list"]}>
                  <PreClosingTimeList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/permissions"
              element={
                <ProtectedRoute requiredPermissions={["/permissions"]}>
                  <PermissionsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/place-bets"
              element={
                <ProtectedRoute requiredPermissions={["/place-bets"]}>
                  <PlaceBets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/send-message"
              element={
                <ProtectedRoute requiredPermissions={["/send-message"]}>
                  <Messages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <ProtectedRoute requiredPermissions={["/accounts"]}>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hierarchy-syso-accounts"
              element={
                <ProtectedRoute
                  requiredPermissions={["/hierarchy-syso-accounts"]}
                >
                  <HierarchyAccounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lower-game-play-details"
              element={
                <ProtectedRoute
                  requiredPermissions={["/lower-game-play-details"]}
                >
                  <LowerGameDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path="/wallet-statements"
              element={
                <ProtectedRoute requiredPermissions={["/wallet-statements"]}>
                  <WalletStatementPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/game-wallet-statements"
              element={
                <ProtectedRoute
                  requiredPermissions={["/game-wallet-statements"]}
                >
                  <GameWalletStatementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/send-photo"
              element={
                <ProtectedRoute requiredPermissions={["/send-photo"]}>
                  <SendPhoto />
                </ProtectedRoute>
              }
            />
            <Route path="/" />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={closeSnackbar}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "darkgreen",
            color: "#fff",
            height: "50px",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarError}
        autoHideDuration={3000}
        onClose={closeSnackbarError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={closeSnackbarError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <Subscription /> */}
    </>
  );
}

export default App;
