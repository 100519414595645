import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Sidebar from "../../components/sidebar/sidebar";
import { useAppStore } from "../../appStore";
import { CameraAlt, UploadFile } from "@mui/icons-material";
import {
  uploadPhoto,
  markPhotoAsSent,
  getPhotosForUser,
} from "./sendPhotoApis";
import { base_url, img_url } from "../../apiUtils";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImageUtils";

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// Assuming you have the URL of the image, you can print it as follows:
// Function to print the image and log each step
const printImage = (imageUrl) => {
  console.log("Attempting to print image...", imageUrl);

  // Open a new window to insert the image for printing
  const printWindow = window.open("", "", "width=600,height=600");

  // Check if the print window opened successfully
  if (printWindow) {
    console.log("Print window opened successfully.");

    // Write the HTML content with the image to the print window
    printWindow.document.write(`
      <html>
        <head><title>Print Image</title></head>
        <body>
          <img src="${imageUrl}" style="width: 100%; height: auto;" />
        </body>
      </html>
    `);

    // Close the document stream to ensure the content is fully loaded
    printWindow.document.close();
    console.log("Image HTML written to print window.");

    // Trigger the print dialog
    printWindow.print();
    console.log("Print dialog triggered.");

    // Close the print window after printing
    printWindow.close();
    console.log("Print window closed.");
  } else {
    console.log("Failed to open print window.");
  }
};

function SendPhoto() {
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const { profileData } = useAppStore();
  const [photoOption, setPhotoOption] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  // const [recipientId, setRecipientId] = useState("64e5f819d39e847147f10623");
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  useEffect(() => {
    const fetchRecipientPhotos = async () => {
      if (profileData) {
        try {
          const recipientPhotos = await getPhotosForUser(profileData?._id);
          setPhotos(recipientPhotos);
          console.log("recipet", recipientPhotos);
          handleImageReceived(recipientPhotos[0].imageUrl);
        } catch (error) {
          console.error("Failed to retrieve recipient photos", error);
        }
      }
    };
    fetchRecipientPhotos();
    // Call the function when a new image is received and ready to print
    // Function to simulate handling the image when it's received
    const handleImageReceived = (imageUrl) => {
      console.log("Received image, triggering print...", imageUrl);
      if (imageUrl) {
        console.log("if image", imageUrl);
        printImage(`${img_url}${imageUrl}`); // Pass the image URL to print
      } else {
        console.log("No image URL provided.");
      }
    };
  }, [profileData]);

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCapturedImage(imageUrl);
      setShowCropper(true);
      // const formData = new FormData();
      // formData.append("image", file);
      // formData.append("senderId", profileData._id);
      // formData.append("recipientId", recipientId);

      // try {
      //   const uploadedPhoto = await uploadPhoto(formData);
      //   setPhotos((prev) => [
      //     ...prev,
      //     {
      //       imageUrl: uploadedPhoto.imageUrl,
      //       date: new Date().toLocaleString(),
      //       id: uploadedPhoto._id,
      //       imageSendStatus: false,
      //     },
      //   ]);
      // } catch (error) {
      //   console.error("Error uploading photo:", error);
      // }

      setPhotoOption(null);
    }
  };

  const handleTakePhoto = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(
        capturedImage,
        croppedAreaPixels
      );
      const formData = new FormData();
      formData.append("image", dataURLtoFile(croppedImage, "croppedImage.jpg"));
      formData.append("senderId", profileData._id);
      // formData.append("recipientId", recipientId);

      const uploadedPhoto = await uploadPhoto(formData);
      setPhotos((prev) => [
        ...prev,
        {
          imageUrl: uploadedPhoto.imageUrl,
          date: new Date().toLocaleString(),
          id: uploadedPhoto._id,
        },
      ]);
      setShowCropper(false);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handleOpenModal = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const handleCloseModal = () => {
    setPreviewImage(null);
  };

  const handleSendImage = async (photoId) => {
    try {
      await markPhotoAsSent(photoId);
      setPhotos((prev) =>
        prev.map((photo) =>
          photo.id === photoId
            ? { ...photo, imageSendStatus: "success" }
            : photo
        )
      );
    } catch (error) {
      console.error("Error marking photo as sent", error);
    }
  };

  const handleResendImage = async (photo) => {
    try {
      const resendPayload = {
        photoId: photo._id,
        imageUrl: `${photo.imageUrl}`,
        senderId: profileData._id,
        // recipientId: recipientId,
      };

      const response = await fetch(`${base_url}sendPhoto/resend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accesstoken: sessionStorage.getItem("accesstoken"),
        },
        body: JSON.stringify(resendPayload),
      });

      const updatedPhoto = await response.json();

      if (response.ok) {
        setPhotos((prev) =>
          prev.map((item) =>
            item._id === photo._id
              ? {
                  ...item,
                  imageSendStatus: "success",
                  date: new Date().toLocaleString(),
                }
              : item
          )
        );
      } else {
        console.error("Error resending image:", updatedPhoto);
      }
    } catch (error) {
      console.error("Error resending image:", error);
    }
  };

  const handleGalleryClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 2 }}>
          <Box height={70} />
          <Box>
            <Typography variant="h4" mb={2}>
              Send Photo
            </Typography>
            <div>
              <Button
                variant="contained"
                sx={{ background: "#e53935" }}
                onClick={handleGalleryClick}
              >
                <UploadFile /> Upload from Gallery
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setPhotoOption("camera")}
                sx={{ ml: 2 }}
              >
                <CameraAlt /> Take Photo
              </Button>
              <Box sx={{ mt: 2 }}>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <input
                  ref={cameraInputRef}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                {photoOption === "camera" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleTakePhoto}
                    sx={{ mt: 2 }}
                  >
                    Start Camera
                  </Button>
                )}
              </Box>
            </div>

            {showCropper && (
              <Modal open={showCropper} onClose={() => setShowCropper(false)}>
                <Box sx={{ position: "relative", width: "100%", height: 400 }}>
                  <Cropper
                    image={capturedImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                  <Button
                    variant="contained"
                    onClick={handleCropConfirm}
                    sx={{ mt: 2 }}
                  >
                    Confirm Crop
                  </Button>
                </Box>
              </Modal>
            )}
            {/* image table */}
            <TableContainer
              sx={{
                mt: 3,
                overflowX: "auto",
                // border: "1px solid #ddd",
                // borderRadius: 1,
              }}
            >
              <Table sx={{ minWidth: 650 }}>
                {/* Table Header */}
                <thead>
                  <TableRow sx={{ backgroundColor: "#d81b60", color: "white" }}>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Sl.No.
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Photo
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </thead>
                {/* Table Body */}
                <TableBody>
                  {photos.map((photo, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                        padding: "4px 0", // Reduce padding of the row itself
                      }}
                    >
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          padding: "4px", // Reduce padding in individual cells
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      {/* Image Cell */}
                      <TableCell
                        onClick={() =>
                          handleOpenModal(`${img_url}${photo.imageUrl}`)
                        }
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          cursor: "pointer",
                          padding: "4px", // Reduce padding in image cell
                        }}
                      >
                        <img
                          src={`${img_url}${photo.imageUrl}`}
                          alt={`Uploaded ${index}`}
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: "cover",
                            borderRadius: "10%",
                          }}
                        />
                      </TableCell>
                      {/* Date Cell */}
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          padding: "4px", // Reduce padding in date cell
                        }}
                      >
                        {new Date(photo.date).toLocaleString("en-GB")}
                      </TableCell>
                      {/* Status Cell */}
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          padding: "4px", // Reduce padding in status cell
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color:
                              photo.imageSendStatus === "success"
                                ? "green"
                                : photo.imageSendStatus === "failed"
                                ? "red"
                                : "transparent", // Initially transparent
                            fontWeight: "bold",
                          }}
                        >
                          {photo.imageSendStatus === "success"
                            ? "Success"
                            : photo.imageSendStatus === "failed"
                            ? "Failed"
                            : null}{" "}
                          {/* Do not display anything if no status is set */}
                        </Typography>
                      </TableCell>
                      {/* Actions Cell */}
                      <TableCell
                        sx={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          padding: "4px", // Reduce padding in actions cell
                        }}
                      >
                        {/* Resend Button */}
                        {photo.imageSendStatus === "failed" ? (
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={() => handleResendImage(photo)}
                            sx={{
                              ml: 2,
                              padding: "4px 8px", // Reduce padding for the resend button
                            }}
                          >
                            Resend
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => handleSendImage(photo.id)}
                            disabled={photo.imageSendStatus}
                            sx={{
                              "&:disabled": {
                                backgroundColor: "#b2dfdb",
                              },
                              color: "#fff",
                              background: "#43a047",
                              padding: "4px 8px", // Reduce padding for the button
                            }}
                          >
                            {photo.imageSendStatus ? "Sent" : "Send"}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Image Preview Modal */}
            <Modal open={!!previewImage} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  padding: 2,
                }}
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{ width: "100%", height: 500, objectFit: "cover" }}
                />
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SendPhoto;
